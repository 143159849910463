import { MenuOutlined } from '@ant-design/icons';
import { useReviewCycleAdminStatus } from '@client/ReviewCyclesClient';
import { dateString } from '@shared/dateString';
import { ProductName, productDetails } from '@shared/reflections';
import {
  IReviewCycle,
  ReviewCycleToken,
  ScheduleMode,
  ShareMode,
} from '@shared/review-cycles';
import { patch, put } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { useModalConfirm } from '@web/common/useModalConfirm';
import { SelectDate, SelectDateRange } from '@web/components/SelectDate';
import { SelectHour } from '@web/components/SelectDateHour';
import { Column, Row, WrapRow } from '@web/components/layout';
import { Text } from '@web/components/text';
import { Header3 } from '@web/components/typography';
import { Button, Dropdown, MenuProps, message } from 'antd';
import pluralize from 'pluralize';
import React from 'react';
import styled from 'styled-components';

interface IReviewCycleConfig {
  name: string;
  scheduleMode: ScheduleMode;
  peerReviewCycleEnabled: boolean;
  peerSelectionStartDate?: string;
  peerSelectionEndDate?: string;
  peerApprovalStartDate?: string;
  peerApprovalEndDate?: string;
  peerFeedbackStartDate?: string;
  peerFeedbackEndDate?: string;
  peerReviewShareMode: ShareMode;
  upwardReviewCycleEnabled: boolean;
  upwardFeedbackStartDate?: string;
  upwardFeedbackEndDate?: string;
  selfReflectionCycleEnabled: boolean;
  selfReflectionProductName: ProductName;
  selfReviewStartDate?: string;
  selfReviewEndDate?: string;
  managerReviewProductName: ProductName;
  managerReflectionCycleEnabled: boolean;
  managerReviewStartDate?: string;
  managerReviewEndDate?: string;
  sharedByDate?: string;
  notificationHour?: number;
}

export const EditReviewCycleScheduleForm: React.FC<{
  onChange: () => void;
  reviewCycleToken: ReviewCycleToken;
  modalButtonMode?: boolean;
}> = ({ onChange, reviewCycleToken, modalButtonMode }) => {
  const { data: reviewCycle } = useApi<IReviewCycle>(
    `/review-cycles/${reviewCycleToken}`,
  );
  const { data: reviewCycleStatus } =
    useReviewCycleAdminStatus(reviewCycleToken);
  const { confirm, contextHolder } = useModalConfirm();
  const [reviewCycleConfig, setReviewCycleConfig] =
    React.useState<IReviewCycleConfig>({} as any);
  React.useEffect(() => {
    if (reviewCycle) {
      const updatedConfig: IReviewCycleConfig = {
        name: reviewCycle.name,
        scheduleMode: reviewCycle.scheduleMode,
        peerReviewCycleEnabled: reviewCycle.peerReviewCycleEnabled,
        peerSelectionStartDate: reviewCycle.peerSelectionStartDate
          ? dateString(reviewCycle.peerSelectionStartDate)
          : null,
        peerSelectionEndDate: reviewCycle.peerSelectionEndDate
          ? dateString(reviewCycle.peerSelectionEndDate)
          : null,
        peerApprovalStartDate: reviewCycle.peerApprovalStartDate
          ? dateString(reviewCycle.peerApprovalStartDate)
          : null,
        peerApprovalEndDate: reviewCycle.peerApprovalEndDate
          ? dateString(reviewCycle.peerApprovalEndDate)
          : null,
        peerFeedbackStartDate: reviewCycle.peerFeedbackStartDate
          ? dateString(reviewCycle.peerFeedbackStartDate)
          : null,
        peerFeedbackEndDate: reviewCycle.peerFeedbackEndDate
          ? dateString(reviewCycle.peerFeedbackEndDate)
          : null,
        peerReviewShareMode: reviewCycle.peerReviewShareMode,
        upwardReviewCycleEnabled: reviewCycle.upwardReviewCycleEnabled,
        upwardFeedbackStartDate: reviewCycle.upwardFeedbackStartDate
          ? dateString(reviewCycle.upwardFeedbackStartDate)
          : null,
        upwardFeedbackEndDate: reviewCycle.upwardFeedbackEndDate
          ? dateString(reviewCycle.upwardFeedbackEndDate)
          : null,
        selfReflectionCycleEnabled: reviewCycle.selfReflectionCycleEnabled,
        selfReviewStartDate: reviewCycle.selfReviewStartDate
          ? dateString(reviewCycle.selfReviewStartDate)
          : null,
        selfReviewEndDate: reviewCycle.selfReviewEndDate
          ? dateString(reviewCycle.selfReviewEndDate)
          : null,
        selfReflectionProductName: reviewCycle.selfReflectionProductName,
        managerReviewProductName: reviewCycle.managerReviewProductName,
        managerReflectionCycleEnabled:
          reviewCycle.managerReflectionCycleEnabled,
        managerReviewStartDate: reviewCycle.managerReviewStartDate
          ? dateString(reviewCycle.managerReviewStartDate)
          : null,
        managerReviewEndDate: reviewCycle.managerReviewEndDate
          ? dateString(reviewCycle.managerReviewEndDate)
          : null,
        sharedByDate: reviewCycle.sharedByDate
          ? dateString(reviewCycle.sharedByDate)
          : null,
        notificationHour: reviewCycle.notificationHour,
      };
      setReviewCycleConfig(updatedConfig);
    }
  }, [reviewCycle]);

  const handleSave = async () => {
    const scheduleMode = ScheduleMode.UNSCHEDULED;
    setReviewCycleConfig({
      ...reviewCycleConfig,
      scheduleMode,
    });

    await handleUpdate(scheduleMode);
  };

  const handleSchedule = async () => {
    const scheduleMode = ScheduleMode.SCHEDULED;
    setReviewCycleConfig({
      ...reviewCycleConfig,
      scheduleMode,
    });

    await handleUpdate(scheduleMode);
  };

  const handleUpdate = async (scheduleMode: ScheduleMode) => {
    try {
      const body: IReviewCycleConfig = {
        name: reviewCycleConfig.name,
        scheduleMode,
        peerReviewCycleEnabled: reviewCycleConfig.peerReviewCycleEnabled,
        peerSelectionStartDate: reviewCycleConfig.peerSelectionStartDate
          ? `${reviewCycleConfig.peerSelectionStartDate}T23:59:59.999Z`
          : null,
        peerSelectionEndDate: reviewCycleConfig.peerSelectionEndDate
          ? `${reviewCycleConfig.peerSelectionEndDate}T23:59:59.999Z`
          : null,
        peerApprovalStartDate: reviewCycleConfig.peerApprovalStartDate
          ? `${reviewCycleConfig.peerApprovalStartDate}T23:59:59.999Z`
          : null,
        peerApprovalEndDate: reviewCycleConfig.peerApprovalEndDate
          ? `${reviewCycleConfig.peerApprovalEndDate}T23:59:59.999Z`
          : null,
        peerFeedbackStartDate: reviewCycleConfig.peerFeedbackStartDate
          ? `${reviewCycleConfig.peerFeedbackStartDate}T23:59:59.999Z`
          : null,
        peerFeedbackEndDate: reviewCycleConfig.peerFeedbackEndDate
          ? `${reviewCycleConfig.peerFeedbackEndDate}T23:59:59.999Z`
          : null,
        peerReviewShareMode: reviewCycleConfig.peerReviewShareMode,
        upwardReviewCycleEnabled: reviewCycleConfig.upwardReviewCycleEnabled,
        upwardFeedbackStartDate: reviewCycleConfig.upwardFeedbackStartDate
          ? `${reviewCycleConfig.upwardFeedbackStartDate}T23:59:59.999Z`
          : null,
        upwardFeedbackEndDate: reviewCycleConfig.upwardFeedbackEndDate
          ? `${reviewCycleConfig.upwardFeedbackEndDate}T23:59:59.999Z`
          : null,
        selfReflectionCycleEnabled:
          reviewCycleConfig.selfReflectionCycleEnabled,
        selfReviewStartDate: reviewCycleConfig.selfReviewStartDate
          ? `${reviewCycleConfig.selfReviewStartDate}T23:59:59.999Z`
          : null,
        selfReviewEndDate: reviewCycleConfig.selfReviewEndDate
          ? `${reviewCycleConfig.selfReviewEndDate}T23:59:59.999Z`
          : null,
        selfReflectionProductName: reviewCycleConfig.selfReflectionProductName,
        managerReviewProductName: reviewCycleConfig.managerReviewProductName,
        managerReflectionCycleEnabled:
          reviewCycleConfig.managerReflectionCycleEnabled,
        managerReviewStartDate: reviewCycleConfig.managerReviewStartDate
          ? `${reviewCycleConfig.managerReviewStartDate}T23:59:59.999Z`
          : null,
        managerReviewEndDate: reviewCycleConfig.managerReviewEndDate
          ? `${reviewCycleConfig.managerReviewEndDate}T23:59:59.999Z`
          : null,
        sharedByDate: reviewCycleConfig.sharedByDate
          ? `${reviewCycleConfig.sharedByDate}T23:59:59.999Z`
          : null,
        notificationHour: reviewCycleConfig.notificationHour,
      };

      const url = `/review-cycles/${reviewCycleToken}`;
      await patch<IReviewCycleConfig, IReviewCycle>(url, body);
      void message.success('Success');
      onChange();
    } catch (error) {
      void message.error(error.displayMessage ?? 'Error');
    }
  };

  const handleDateUpdated =
    (property: keyof IReviewCycleConfig) => (date?: string) => {
      setReviewCycleConfig({
        ...reviewCycleConfig,
        [property]: date,
      });
    };
  const handleDateRangeUpdated =
    (
      startProperty: keyof IReviewCycleConfig,
      endProperty: keyof IReviewCycleConfig,
    ) =>
    (startDate: string, endDate: string) => {
      if (
        startProperty === 'peerSelectionStartDate' &&
        endProperty === 'peerSelectionEndDate'
      ) {
        setReviewCycleConfig({
          ...reviewCycleConfig,
          [startProperty]: startDate,
          [endProperty]: endDate,
          peerApprovalStartDate: startDate,
          peerApprovalEndDate: endDate,
        });
      } else {
        setReviewCycleConfig({
          ...reviewCycleConfig,
          [startProperty]: startDate,
          [endProperty]: endDate,
        });
      }
    };

  const handleNotificationHourChanged = (notificationHour: number) => {
    setReviewCycleConfig({
      ...reviewCycleConfig,
      notificationHour,
    });
  };

  const selfReviewsName = reviewCycleConfig.selfReflectionProductName
    ? `Self ${pluralize(
        productDetails(reviewCycleConfig.selfReflectionProductName).titleCase,
      )}`
    : ``;
  const managerReviewsName = reviewCycleConfig.managerReviewProductName
    ? `Manager ${pluralize(
        productDetails(reviewCycleConfig.managerReviewProductName).titleCase,
      )}`
    : ``;

  const handleStartCycle = async () => {
    if (
      !(await confirm(
        'Confirm starting this cycle and sending the intro notification.',
      ))
    ) {
      void message.info('Here');
      return;
    }
    try {
      await put(`/review-cycles/${reviewCycle.token}/send-cycle-introduction`);
      void message.success('Success');
      onChange();
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleStartPeerSelection = async () => {
    if (!(await confirm('Confirm starting Peer Selection.'))) {
      void message.info('Here');
      return;
    }
    try {
      await put(`/review-cycles/${reviewCycle.token}/peerReviewCycle`);
      void message.success('Success');
      onChange();
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleSendPeerFeedbackRequests = async () => {
    if (!(await confirm('Confirm sending Peer Feedback requests.'))) {
      return;
    }
    try {
      await put(`/review-cycles/${reviewCycle.token}/peerFeedbackRequests`);
      void message.success('Success');
      onChange();
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleSendUpwardFeedbackRequests = async () => {
    if (!(await confirm('Confirm sending Upward Feedback requests.'))) {
      return;
    }
    try {
      await put(`/review-cycles/${reviewCycle.token}/upwardFeedbackRequests`);
      void message.success('Success');
      onChange();
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleStartSelfReviewCycle = async () => {
    if (!(await confirm(`Confirm sending ${selfReviewsName}.`))) {
      return;
    }
    try {
      await put(`/review-cycles/${reviewCycle.token}/self-reflection-cycle`);
      void message.success('Success');
      onChange();
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleStartManagerReviewCycle = async () => {
    if (!(await confirm(`Confirm sending ${managerReviewsName}.`))) {
      return;
    }
    try {
      await put(`/review-cycles/${reviewCycle.token}/manager-reflection-cycle`);
      void message.success('Success');
      onChange();
    } catch (error) {
      void message.error('Error');
    }
  };

  const ItemLabel = styled(Text)`
    line-height: 40px;
  `;

  const canStart =
    !reviewCycleStatus?.overall || reviewCycleStatus?.overall.ready;
  const canStartComponent = canStart && reviewCycle.startedDate;

  const items: MenuProps['items'] = [
    {
      key: 'start-cycle',
      label: <ItemLabel>Start Cycle and Send Intro Now</ItemLabel>,
      onClick: handleStartCycle,
      disabled: !canStart || !!reviewCycle.startedDate,
    },
    reviewCycleConfig.peerReviewCycleEnabled
      ? {
          key: 'start-peer-selection',
          label: <ItemLabel>Start Peer Selection Now</ItemLabel>,
          onClick: handleStartPeerSelection,
          disabled: !canStartComponent || !!reviewCycle.peerReviewCycleStarted,
        }
      : null,
    reviewCycleConfig.peerReviewCycleEnabled
      ? {
          key: 'start-peer-feedback',
          label: <ItemLabel>Start Peer Feedback Now</ItemLabel>,
          onClick: handleSendPeerFeedbackRequests,
          disabled:
            !canStartComponent || !!reviewCycle.peerReviewFeedbackRequested,
        }
      : null,
    reviewCycleConfig.upwardReviewCycleEnabled
      ? {
          key: 'start-upward-feedback',
          label: <ItemLabel>Start Upward Feedback Now</ItemLabel>,
          onClick: handleSendUpwardFeedbackRequests,
          disabled:
            !canStartComponent || !!reviewCycle.upwardReviewFeedbackRequested,
        }
      : null,
    reviewCycleConfig.selfReflectionCycleEnabled
      ? {
          key: 'start-self-reviews',
          label: <ItemLabel>Start {selfReviewsName} Now</ItemLabel>,
          onClick: handleStartSelfReviewCycle,
          disabled:
            !canStartComponent || !!reviewCycle.selfReflectionCycleStarted,
        }
      : null,
    reviewCycleConfig.selfReflectionCycleEnabled
      ? {
          key: 'start-manager-reviews',
          label: <ItemLabel>Start {managerReviewsName} Now</ItemLabel>,
          onClick: handleStartManagerReviewCycle,
          disabled:
            !canStartComponent || !!reviewCycle.managerReflectionCycleStarted,
        }
      : null,
  ];

  return (
    <>
      <Column gap={24}>
        <Column gap={12} style={{ alignItems: 'flex-start', width: 794 }}>
          <>
            <Column gap={12}>
              {reviewCycleStatus?.overall && (
                <WrapRow gap={12}>
                  <Header3>{reviewCycleStatus.overall.icon}</Header3>
                  <Header3>{reviewCycleStatus.overall.message}</Header3>
                  <Header3>{reviewCycleStatus.overall.icon}</Header3>
                </WrapRow>
              )}
            </Column>
          </>
          {reviewCycleConfig.peerReviewCycleEnabled && (
            <>
              <Column>
                <Text>Peer Selection</Text>
                <WrapRow gap={12}>
                  <Column style={{ width: 250 }}>
                    <SelectDateRange
                      start={reviewCycleConfig.peerSelectionStartDate}
                      end={reviewCycleConfig.peerSelectionEndDate}
                      onChange={handleDateRangeUpdated(
                        'peerSelectionStartDate',
                        'peerSelectionEndDate',
                      )}
                      allowClear={true}
                      disabled={[!!reviewCycle.peerReviewCycleStarted, false]}
                    />
                  </Column>
                  {reviewCycle.peerReviewCycleStarted && (
                    <Column style={{ width: 300, alignSelf: 'center' }}>
                      <Text>✅ Started</Text>
                    </Column>
                  )}
                </WrapRow>
              </Column>
              <Column>
                <Text>Peer Feedback</Text>
                <WrapRow gap={12}>
                  <Column style={{ width: 250 }}>
                    <SelectDateRange
                      start={reviewCycleConfig.peerFeedbackStartDate}
                      end={reviewCycleConfig.peerFeedbackEndDate}
                      onChange={handleDateRangeUpdated(
                        'peerFeedbackStartDate',
                        'peerFeedbackEndDate',
                      )}
                      allowClear={true}
                      disabled={[
                        !!reviewCycle.peerReviewFeedbackRequested,
                        false,
                      ]}
                    />
                  </Column>
                  {reviewCycle.peerReviewFeedbackRequested && (
                    <Column style={{ width: 300, alignSelf: 'center' }}>
                      <Text>✅ Started</Text>
                    </Column>
                  )}
                </WrapRow>
              </Column>
            </>
          )}
          {reviewCycleConfig.upwardReviewCycleEnabled && (
            <>
              <Column>
                <Text>Upward Feedback</Text>
                <WrapRow gap={12}>
                  <Column style={{ width: 250 }}>
                    <SelectDateRange
                      start={reviewCycleConfig.upwardFeedbackStartDate}
                      end={reviewCycleConfig.upwardFeedbackEndDate}
                      onChange={handleDateRangeUpdated(
                        'upwardFeedbackStartDate',
                        'upwardFeedbackEndDate',
                      )}
                      allowClear={true}
                      disabled={[
                        !!reviewCycle.upwardReviewFeedbackRequested,
                        false,
                      ]}
                    />
                  </Column>
                  {reviewCycle.upwardReviewFeedbackRequested && (
                    <Column style={{ width: 300, alignSelf: 'center' }}>
                      <Text>✅ Started</Text>
                    </Column>
                  )}
                </WrapRow>
              </Column>
            </>
          )}
          {reviewCycleConfig.selfReflectionCycleEnabled && (
            <>
              <Column>
                <Text>{selfReviewsName}</Text>
                <WrapRow gap={12}>
                  <Column style={{ width: 250 }}>
                    <SelectDateRange
                      start={reviewCycleConfig.selfReviewStartDate}
                      end={reviewCycleConfig.selfReviewEndDate}
                      onChange={handleDateRangeUpdated(
                        'selfReviewStartDate',
                        'selfReviewEndDate',
                      )}
                      allowClear={true}
                      disabled={[
                        !!reviewCycle.selfReflectionCycleStarted,
                        false,
                      ]}
                    />
                  </Column>
                  {reviewCycle.selfReflectionCycleStarted && (
                    <Column style={{ width: 300, alignSelf: 'center' }}>
                      <Text>✅ Started</Text>
                    </Column>
                  )}
                </WrapRow>
              </Column>
            </>
          )}
          {reviewCycleConfig.managerReflectionCycleEnabled && (
            <>
              <Column>
                <Text>{managerReviewsName}</Text>
                <WrapRow gap={12}>
                  <Column style={{ width: 250 }}>
                    <SelectDateRange
                      start={reviewCycleConfig.managerReviewStartDate}
                      end={reviewCycleConfig.managerReviewEndDate}
                      onChange={handleDateRangeUpdated(
                        'managerReviewStartDate',
                        'managerReviewEndDate',
                      )}
                      allowClear={true}
                      disabled={[
                        !!reviewCycle.managerReflectionCycleStarted,
                        false,
                      ]}
                    />
                  </Column>
                  {reviewCycle.managerReflectionCycleStarted && (
                    <Column style={{ width: 300, alignSelf: 'center' }}>
                      <Text>✅ Started</Text>
                    </Column>
                  )}
                </WrapRow>
              </Column>
            </>
          )}
          {reviewCycleConfig.managerReflectionCycleEnabled && (
            <>
              <Column>
                <Text>Shared By (optional)</Text>
                <WrapRow gap={12}>
                  <Column style={{ width: 250 }}>
                    <SelectDate
                      value={reviewCycleConfig.sharedByDate}
                      onChange={handleDateUpdated('sharedByDate')}
                    />
                  </Column>
                </WrapRow>
              </Column>
            </>
          )}
          <Column>
            <Text>Notification Time</Text>
            <SelectHour
              hour={reviewCycleConfig.notificationHour}
              onHourChanged={handleNotificationHourChanged}
            />
          </Column>
        </Column>
        <Column>
          <PaneButtons
            style={{
              marginTop: 12,
              flexDirection: modalButtonMode ? 'row-reverse' : 'row',
            }}
          >
            <Button
              type={
                reviewCycle.scheduleMode === ScheduleMode.SCHEDULED
                  ? 'default'
                  : 'primary'
              }
              disabled={!canStart}
              onClick={handleSchedule}
            >
              {reviewCycle.scheduleMode === ScheduleMode.SCHEDULED
                ? 'Update Schedule'
                : 'Schedule'}
            </Button>
            <Button onClick={handleSave}>
              {reviewCycle.scheduleMode === ScheduleMode.SCHEDULED
                ? 'Unschedule'
                : 'Save Draft'}
            </Button>
            <Dropdown menu={{ items }} trigger={['click']}>
              <Button style={{ width: 160 }}>
                Manual Actions
                <MenuOutlined />
              </Button>
            </Dropdown>
          </PaneButtons>
        </Column>
      </Column>
      {contextHolder}
    </>
  );
};

export const PaneButtons = styled(Row)`
  gap: 6px;
`;
