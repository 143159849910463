import {
  cloneQuestion,
  removeQuestion,
  sortQuestionSet,
  useQuestionSet,
} from '@client/QuestionSetsClient';
import { QuestionType } from '@shared/QuestionType';
import { mapByToken } from '@shared/mapByToken';
import { IQuestionSet, QuestionSetToken } from '@shared/questionSets';
import { IQuestion, QuestionToken } from '@shared/questions';
import { Column, GrowingSpacer, Row } from '@web/components/layout';
import { QuestionItemRow } from '@web/surveys/questions/QuestionItemRow';
import { Button, Empty, Skeleton, message } from 'antd';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactSortable } from 'react-sortablejs';

import { EditQuestionModal } from './EditQuestionModal';

interface ISortable {
  id: QuestionToken;
}

interface Props {
  questionSetToken: QuestionSetToken;
  readonly?: boolean;
  questionTypes?: QuestionType[];
  onChange: () => void;
  previewUrl?: string;
  preventDeletingOnlyQuestionMessage?: string;
}

const hasChanged = (itemsA: ISortable[], itemsB: ISortable[]) => {
  const idHashA = itemsA.map((item) => item.id).join(':');
  const idHashB = itemsB.map((item) => item.id).join(':');
  return idHashA !== idHashB;
};
export const EditQuestionSet: React.FC<Props> = ({
  questionSetToken,
  readonly,
  questionTypes,
  onChange,
  previewUrl = null,
  preventDeletingOnlyQuestionMessage = null,
}) => {
  const navigate = useNavigate();
  const { data: questionSet, mutate: reloadQuestionSet } =
    useQuestionSet(questionSetToken);
  const [sortedQuestionItems, setSortedQuestionItems] = React.useState<
    ISortable[]
  >([]);
  const [questionMap, setQuestionMap] =
    React.useState<Map<QuestionToken, IQuestion>>();
  React.useEffect(() => {
    if (questionSet) {
      setSortedQuestionItems(createSortableQuestionItems(questionSet));
      setQuestionMap(mapByToken(questionSet.questions));
    }
  }, [questionSet]);
  const [showAddQuestion, setShowAddQuestion] = React.useState(false);
  const [editingQuestionToken, setEditingQuestionToken] = React.useState<
    QuestionToken | undefined
  >(undefined);

  if (!questionSet) {
    return <Skeleton style={{ marginTop: 24 }} />;
  }

  const sortQuestionItems = (newSortedQuestionItems: ISortable[]) => {
    if (!hasChanged(sortedQuestionItems, newSortedQuestionItems)) {
      return;
    }

    void sortQuestionSet(
      questionSet.token,
      newSortedQuestionItems.map((item) => item.id),
    );
    setSortedQuestionItems(newSortedQuestionItems);
  };

  const handleQuestionUpdated = () => {
    setShowAddQuestion(false);
    setEditingQuestionToken(undefined);
    void reloadQuestionSet();
    onChange();
  };

  const handleQuestionCancel = () => {
    setShowAddQuestion(false);
    setEditingQuestionToken(undefined);
  };

  const handleEditQuestion = (questionToken: QuestionToken) => {
    setEditingQuestionToken(questionToken);
  };

  const preventDeletingOnlyQuestion = () => {
    if (
      preventDeletingOnlyQuestionMessage &&
      sortedQuestionItems.length === 1
    ) {
      void message.error(preventDeletingOnlyQuestionMessage);
      return true;
    }

    return false;
  };

  const handleDeleteQuestion = async (questionToken: QuestionToken) => {
    if (preventDeletingOnlyQuestion()) {
      return;
    }
    try {
      await removeQuestion(questionSet.token, questionToken);
      void reloadQuestionSet();
      onChange();
    } catch (error) {
      void message.error('Error');
    }
  };
  const handleCloneQuestion = async (questionToken: QuestionToken) => {
    try {
      await cloneQuestion(questionSet.token, questionToken);
      void message.success('Success');
      void reloadQuestionSet();
    } catch (error) {
      void message.error('Error');
    }
  };

  return (
    <Column>
      <Row gap={6}>
        <GrowingSpacer />
        {!readonly && !questionSet.readonly && (
          <Button
            onClick={() => {
              setShowAddQuestion(true);
            }}
          >
            Add Question
          </Button>
        )}
        {previewUrl && sortedQuestionItems.length > 0 && (
          <Button
            onClick={() => {
              navigate(previewUrl);
            }}
          >
            Preview
          </Button>
        )}
      </Row>
      {sortedQuestionItems.length > 0 ? (
        <ReactSortable
          list={sortedQuestionItems}
          setList={sortQuestionItems}
          easing="cubic-bezier(0.55, 0, 1, 0.45)"
          animation={100}
          handle=".drag-anchor"
          disabled={readonly || questionSet.readonly}
        >
          {sortedQuestionItems.map((item, i) => {
            const question = questionMap.get(item.id);
            if (!question) {
              throw new Error(`Unknown question: ${item.id}`);
            }

            return (
              <QuestionItemRow
                key={question.token}
                question={question}
                position={i + 1}
                draggable={!readonly && !questionSet.readonly}
                onClick={() => {
                  handleEditQuestion(question.token);
                }}
                onDelete={
                  !readonly && !questionSet.readonly
                    ? () => {
                        void handleDeleteQuestion(question.token);
                      }
                    : undefined
                }
                onClone={
                  !readonly && !questionSet.readonly
                    ? () => {
                        void handleCloneQuestion(question.token);
                      }
                    : undefined
                }
              />
            );
          })}
        </ReactSortable>
      ) : (
        <Empty description="No questions have been added" />
      )}
      {(showAddQuestion || !!editingQuestionToken) && (
        <EditQuestionModal
          questionSet={questionSet}
          onUpdate={handleQuestionUpdated}
          onCancel={handleQuestionCancel}
          open={true}
          question={
            editingQuestionToken
              ? questionMap.get(editingQuestionToken)
              : undefined
          }
          readonly={readonly || questionSet.readonly}
          questionTypes={questionTypes}
        />
      )}
    </Column>
  );
};

const createSortableQuestionItems = (
  questionSet: IQuestionSet,
): ISortable[] => {
  return questionSet.sortedQuestions
    .map((questionToken) => ({
      id: questionToken,
    }))
    .filter((questionToken) => !!questionToken) as ISortable[];
};
