import {
  GetReflectionResponse,
  ReflectionState,
  UpdateReflectionRequest,
  UpdateReflectionResponse,
  isManagerReflection,
} from '@shared/reflections';
import {
  UpdateCycleReflectionRequest,
  UpdateCycleReflectionResponse,
} from '@shared/review-cycles';
import { PageContent } from '@web/app/Page';
import { useResponsive } from '@web/app/responsive';
import { useAuth } from '@web/auth/useAuth';
import { ServerResponseError, put } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { useNavigateBack } from '@web/common/useNavigateBack';
import { ErrorPageContent } from '@web/components/ErrorPageContent';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { Spacer } from '@web/components/layout';
import { Skeleton, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ReflectionForm } from './ReflectionForm';
import { pageDescription, pageTitle } from './reflections';

export const EditReflectionPage: React.FC = () => {
  const navigateBack = useNavigateBack();
  const { isMobile } = useResponsive();
  const { reflectionToken } = useParams();
  const { reviewCycleToken } = useParams();
  const { reviewCycleComponentType } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [showTour, setShowTour] = useState(false);

  const isPreview = !!(reviewCycleToken && reviewCycleComponentType);
  const {
    data: response,
    error,
    mutate,
  } = useApi<GetReflectionResponse, ServerResponseError>(
    isPreview
      ? `/review-cycles/${reviewCycleToken}/preview/${reviewCycleComponentType}`
      : `/reflections/${reflectionToken}`,
  );
  const reflection = response?.reflection;
  const questionSet = response?.questionSet;
  const isCycleReflection = !!reflection?.reviewCycleToken;
  useEffect(() => {
    if (reflection && isCycleReflection && !isPreview) {
      if (isManagerReflection(reflection)) {
        navigate(
          `/cycles/${reflection.reviewCycleToken}/users/${reflection.receiverToken}/reflection/edit`,
          { replace: true },
        );
      } else if (!reflection.canEdit) {
        navigate(`/cycles/${reflection.reviewCycleToken}`, {
          replace: true,
        });
      }
    }
  }, [response]);

  if (error) {
    return <ErrorPageContent statusCode={error.statusCode} />;
  } else if (!response?.reflection) {
    return (
      <PageContent>
        <Pane>
          <Skeleton />
        </Pane>
      </PageContent>
    );
  }

  if (isCycleReflection && isManagerReflection(reflection) && !isPreview) {
    return (
      <PageContent>
        <Pane>
          <Skeleton />
        </Pane>
      </PageContent>
    );
  }
  let defaultNavigateBackTo =
    reflection.state === ReflectionState.SHARED
      ? `/reflections/${reflection.token}`
      : `/team/${reflection.receiverToken}/reflections`;
  if (reflection.reviewCycleToken) {
    defaultNavigateBackTo = `/cycles/${reflection.reviewCycleToken}`;
  }

  const handleChange = async () => {
    await mutate();
  };

  const handleCloseTour = () => {
    setShowTour(false);
  };

  const goBack = () => {
    navigateBack(defaultNavigateBackTo);
  };

  const handleSave = async (
    request: UpdateReflectionRequest,
    draft: boolean,
    autoSave: boolean,
  ) => {
    if (isPreview) {
      if (!autoSave) {
        const messageText = draft
          ? `Preview only. Not saving.`
          : `Preview only. Not submitting.`;
        void message.info(messageText);
      }
      return;
    }

    if (isCycleReflection) {
      await put<UpdateCycleReflectionRequest, UpdateCycleReflectionResponse>(
        `/review-cycles/reflections/${reflection.token}`,
        request,
      );
    } else {
      await put<UpdateReflectionRequest, UpdateReflectionResponse>(
        `/reflections/${reflection.token}`,
        request,
      );
    }
    if (!autoSave) {
      void message.success('Success');
      goBack();
    }
  };

  return (
    <PageContent>
      <PageHeader
        title={pageTitle(user, reflection, isPreview)}
        mobileTitle={pageTitle(user, reflection, isPreview)}
        description={isMobile ? undefined : pageDescription(reflection)}
        navigateBack
        defaultNavigateBackTo={defaultNavigateBackTo}
        secondaryActions={
          isCycleReflection
            ? undefined
            : [
                {
                  label: 'Show Tour',
                  onClick: () => {
                    setShowTour(true);
                  },
                },
              ]
        }
      />
      <ReflectionForm
        handleSave={handleSave}
        onChange={handleChange}
        showTour={showTour}
        onCloseTour={handleCloseTour}
        existingReflection={reflection}
        questionSet={questionSet}
      />
      {isMobile && <Spacer />}
    </PageContent>
  );
};
