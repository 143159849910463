import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';

export function parseDayjs(dateString: string) {
  return dayjs(new Date(`${dateString}T23:59:59.999Z`));
}

export const SelectDate: React.FC<{
  value?: string;
  disabled?: boolean;
  onChange: (newValue: string) => void;
  bordered?: boolean;
  placeholder?: string;
  style?: React.CSSProperties;
}> = ({ value, onChange, disabled, bordered, placeholder, style }) => {
  const dayjsValue = value ? parseDayjs(value) : undefined;

  const handleDatePickerChanged = (newDaysJsValue: Dayjs) => {
    const newValue = newDaysJsValue
      ? newDaysJsValue.format('YYYY-MM-DD')
      : undefined;
    onChange(newValue);
  };

  return (
    <DatePicker
      value={dayjsValue}
      disabled={disabled}
      onChange={handleDatePickerChanged}
      allowClear
      bordered={bordered}
      placeholder={placeholder}
      style={style}
    />
  );
};

export const SelectDateRange: React.FC<{
  start?: string;
  end?: string;
  disabled?: boolean | [boolean, boolean];
  onChange: (newStart: string, newEnd: string) => void;
  style?: React.CSSProperties;
  allowClear?: boolean;
}> = ({ start, end, onChange, disabled, style, allowClear }) => {
  const dayjsStart = start ? parseDayjs(start) : undefined;
  const dayjsEnd = end ? parseDayjs(end) : undefined;

  const handleDatePickerChanged = (range: Dayjs[] | null) => {
    if (!range) {
      onChange(null, null);
      return;
    }
    const [dayjsStart, dayjsEnd] = range;
    const startDate = dayjsStart.format('YYYY-MM-DD');
    const endDate = dayjsEnd.format('YYYY-MM-DD');
    onChange(startDate, endDate);
  };

  return (
    <DatePicker.RangePicker
      value={[dayjsStart, dayjsEnd]}
      disabled={disabled}
      onChange={handleDatePickerChanged}
      style={style}
      allowClear={allowClear}
    />
  );
};
